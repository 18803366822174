import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Injector, ErrorHandler, DoBootstrap } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';
import { provideNgxMask, NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { TooltipModule } from '@cloudfactorydk/ng2-tooltip-directive';

import { SafeHtmlPipe } from './pipe/safehtml.pipe';


import { PaymentService } from './services/payment.service';
//import { SESSION_STORAGE_PROVIDERS } from './services/session-storage.service';
import { MEMORY_STORAGE_PROVIDERS, MemoryStorage } from './services/memory-storage.service';
import { AuthInterceptor } from './interceptors/auth.interceptor';

import { GeronigoVouchersComponent } from './components/geronigo-vouchers/geronigo-vouchers.component';
import { BasketModalComponent } from './components/basket-modal/basket-modal.component';
import { VoucherComponent } from './components/voucher/voucher.component';
import { BasketComponent } from './components/basket/basket.component';
import { PaymentComponent } from './components/payment/payment.component';
import { GlobalErrorHandler } from './services/global-error-handler.service';

import { NgxCaptchaModule } from 'ngx-captcha';

const components = [
    GeronigoVouchersComponent,
    BasketModalComponent,
    VoucherComponent,
    BasketComponent,
    PaymentComponent,
    SafeHtmlPipe
];

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, 'https://apistage.geronigo.com/config/lang/', '.json');
}

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [
        SafeHtmlPipe
    ], imports: [BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        NgSelectModule,
        ToastrModule.forRoot({ timeOut: 5000 }),
        NgxMaskDirective, NgxMaskPipe,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        TooltipModule,
        NgxCaptchaModule, ...components], providers: [
        PaymentService,
        MEMORY_STORAGE_PROVIDERS,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        provideNgxMask(),
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule implements DoBootstrap{
    constructor(
        private injector: Injector
    ) { }

    ngDoBootstrap() {
        const elements: any[] = [
            [GeronigoVouchersComponent, 'geronigo-vouchers'],
            [GeronigoVouchersComponent, 'rezbot-vouchers'],
            [BasketModalComponent, 'basket-modal']
        ];

        for (const [component, name] of elements) {
            if (customElements.get(name)) continue;

            const el = createCustomElement(component, { injector: this.injector });

            customElements.define(name, el);
        }
    }
}
