import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';

import pick from 'lodash/pick';
import { MemoryStorage } from 'src/app/services/memory-storage.service';

import { format, addMonths, isBefore, isAfter } from 'date-fns'

import {register} from 'swiper/element/bundle';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
register();

import { Swiper, SwiperOptions }  from 'swiper/types';
import { NgxMaskDirective } from 'ngx-mask';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgSelectComponent, NgLabelTemplateDirective, NgOptionTemplateDirective } from '@ng-select/ng-select';
import { CurrencyPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-voucher',
    templateUrl: './voucher.component.html',
    styleUrls: ['./voucher.component.sass'],
    imports: [FormsModule, ReactiveFormsModule, NgxMaskDirective, FaIconComponent, NgSelectComponent, NgLabelTemplateDirective, NgOptionTemplateDirective, CurrencyPipe, TranslateModule],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class VoucherComponent implements OnInit, OnDestroy {
    @Output() deleted: EventEmitter<void> = new EventEmitter();

    @Input() form: UntypedFormGroup;
    @Input() vouchers: UntypedFormArray;
    @Input() company: any;
    @Input() index: number;
    @Input() currency = 'USD';
    @Input() currencySymbol = '$';
    @Input() brandId: string = '';

    public faTimes = faTimes;
    public faChevronLeft = faChevronLeft;
    public faChevronRight = faChevronRight;

    public directories: any;
    public expiry = format(addMonths(new Date(), 12),'MMM. do yyyy');
    public swiperIndex = 0;
    public maxIndex = 0;
    public wrapOptions: any[] = [];
    public mailServices: any[] = [];
    public hint = '';

    public filteredImages: any[] = [];
    public imageHistory: {[key:string]:string} = {};

    private formChangeSub: Subscription;

    constructor(
        private memoryStorage: MemoryStorage
    ) { }

    ngOnInit() {
        this.directories = JSON.parse(this.memoryStorage.getItem('directories'))

        let valid = this.company?.voucher_valid || 12
        if(this.currency == 'AUD' && this.company.voucher_valid < 36 ) this.company.voucher_valid = 36

        this.expiry = format(addMonths(new Date(), valid ),'MMM. do yyyy');

        let today = new Date().toISOString().replace('T',' ').substring(0,19);

        let wrapOptions = this.directories.wrap_options.
        filter(item=>(!item?.date_from || item?.date_from <= today) && (!item?.date_to || item?.date_to >= today)).
        filter(item=>(!this.brandId || !item?.brand_ids || item?.brand_ids.length == 0 || item?.brand_ids.find(brand=>brand==this.brandId)?.length>0)).
        map(item => ({ ...item, price: +item.price }));

        let mailServices = this.directories.mail_services.
        filter(item=>(!item?.date_from || item?.date_from <= today) && (!item?.date_to || item?.date_to >= today)).
        map(item => ({ ...item, price: +item.price }));

        this.mailServices = mailServices.filter(item =>
            (item.currency === null || item.currency == this.currency) && (item.tag !== 'email' && item.tag !== 'collection')
        ).sort((a, b) => {
            if (a.price > b.price) return 1;
            if (a.price < b.price) return -1;
            return 0;
        });

        let _currency = this.currency;
        this.wrapOptions = wrapOptions.filter(item => 
            (item.currency === null || item.currency == this.currency) && (item.tag === 'email' || this.mailServices.length > 0)
        ).sort((a, b) => {
            if (a.price > b.price) return 1;
            if (a.price < b.price) return -1;

            return 0;
        });
        

        let woptions:{[key:string]:any} = {};
        let moptions:{[key:string]:any} = {};
        this.wrapOptions.forEach(it=>{
            let key = it.tag;
            if(woptions[key] === undefined || +woptions[key].price > +it.price) woptions[key] = it;
        })
        this.wrapOptions = [...Object.values(woptions)];

        this.mailServices.forEach(it=>{
            let key = it.tag;
            if(moptions[key] === undefined || +moptions[key].price > +it.price) moptions[key] = it;
        })
        this.mailServices = [...Object.values(moptions)];

        if (this.company?.voucher_config?.presets?.length) {
            this.maxIndex = Math.floor(this.company.voucher_config.presets.length / 3);
        }
        this.filterImages('email');

        if(this.wrapOptions.length==1){
            this.setWrapOption(this.wrapOptions[0]);
        }

        this.setHistory();
        setTimeout(() => this.checkHint());
    }

    ngOnDestroy() {
        if (this.formChangeSub) this.formChangeSub.unsubscribe();
    }

    public setHistory(){
        let wos = this.wrapOptions.filter(it=>this.form.get('wrap_option_id').value == it.wrap_option_id);
        if(wos[0]){
            this.imageHistory[wos[0].tag] = this.form.get('design').value;
        }
    }

    public changePath() {
        if(!this.company?.logo) return;
        if (this.company?.logo.startsWith('https://rezbot.net')) return;

        const { resources } = this.directories;

        this.company.logo = this.company.logo.replace(resources.images_path, resources.fallback_path);
    }

    public setPreset(item: any): void {
        this.form.get('preset').setValue(item);
        this.form.get('value').setValue(item.price);
    }

    public setWrapOption(item: any): void {
        let func = 'enable';

        if(this.wrapOptions.length == 1){
            item.tag = this.wrapOptions[0].tag
        }

        const value :any= {
            mail_service_id: '',
            tag: item.tag,
            image: item.image
        };

        if (item.tag === 'email') {
            const mail = this.directories.mail_services.find(val => val.tag === 'email');

            value.mail_service_id = mail.mail_service_id;

            func = 'disable';
        } else {
            value.mail_service_id = this.mailServices[0].mail_service_id;
        }

        let voucher = this.vouchers.at(this.index).value;

        if(!voucher?.preset && !voucher.fixed_image){
            this.filterImages(item.tag);

            let found = this.filteredImages.filter(img=>this.imageHistory[item.tag] == img.full_url)
            let url = this.filteredImages[0].full_url



            if(found.length > 0) url = found[0].full_url;
            value.design = url;
            value.image = url;
        }

        

        ['delivery_name','address_1', 'address_2', 'town', 'zip'].forEach(val => this.form.get(val)[func]());

        this.form.patchValue(value);
        this.setHistory();
    }

    public filterImages(tag:string){
        this.filteredImages = this.company.images.filter(it=>it.caption==`wrap_option:${this.company.company_id}:${tag}`)
        if(this.filteredImages.length == 0){
            this.filteredImages = this.company.images.filter(it=>it.caption==`wrap_option:${this.company.company_id}:email`)
        }
        if(this.filteredImages.length == 0){
            this.filteredImages = this.company.images;
        }
    }

    public editVoucherValue(value: number): void {
        const control = this.form.get('value');
        const val = control.value + value;

        if (val <= this.company?.voucher_config?.max && val >= this.company?.voucher_config?.min) control.setValue(val);

        this.checkHint();
    }

    public copyAddress(): void {
        const addressField = ['delivery_name','address_1', 'address_2', 'town', 'zip'];
        const voucher = this.vouchers.at(this.index - 1).value;

        this.form.patchValue(pick(voucher, addressField));
    }

    private checkHint(): void {
        if (!this.company?.voucher_config || !this.company?.voucher_config?.hints) return;

        const hint = this.company?.voucher_config?.hints.find(item => item.val === this.form.get('value').value);

        if (hint) this.hint = hint.text;
        else this.hint = '';
    }

    public needsLogo(string: string){
        let parts = string.split('/');
        return +(parts[parts.length-1].split('.')[0]) > 54700;
    }
}
