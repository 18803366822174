<div class="gn-voucher-imports" [attr.data-cart]="(sale?.vouchers??[]).length" [ngClass]="theme" style="overflow:clip"
  [style.max-height]="modalDisplayed === true ?'200px':''">

  <!-- [style.overflow]="modalDisplayed === true ? 'clip':'auto'" -->
  @if (!loading) {
    @if (company && paymentsSetup && cart === '0') {
      <div class="voucher-add gn-w-full gn-flex gn-flex-col gn-items-center gn-justify-center">
        <p class="voucher-add-intro gn-text-center gn-font-bold gn-mb-4">Please enter a gift voucher value below:</p>
        <form class="gn-flex gift-select" (ngSubmit)="openModal()">
          <div class="gn-flex gn-mr-2 gn-border gn-border-gray-500 gn-rounded">
            <button type="button" (click)="editVoucherValue(-company.voucher_config.step)" class="gn-px-3 gn-text-lg gn-text-gray-700 gn-font-medium gn-bg-gray-200 hover:gn-bg-gray-400 gn-flex gn-items-center gn-justify-center">
              <span>-</span>
            </button>
            <input [formControl]="amount" [prefix]="currency" mask="0*" class="input gn-rounded-none gn-w-20 sm:gn-w-16 gn-border-0" type="text" placeholder="Amount"/>
            <button type="button" (click)="editVoucherValue(company.voucher_config.step)" class="gn-px-3 gn-text-lg gn-text-gray-700 gn-font-medium gn-bg-gray-200 hover:gn-bg-gray-400 gn-flex gn-items-center gn-justify-center">
              <span>+</span>
            </button>
          </div>
          <button type="submit" class="gn-text-white gn-bg-main gn-px-4 gn-rounded gn-mr-2">
            <fa-icon [icon]="faGiftCard"></fa-icon> {{ 'buttons.buy_now' | translate }}
          </button>
          @if (viewBasket.length) {
            <button type="button" class="gn-text-white gn-bg-gray-700 gn-px-4 gn-rounded" (click)="showBasket()">
              <fa-icon [icon]="faShoppingBasket"></fa-icon> {{ 'buttons.view_basket' | translate }} ({{ viewBasket.length }})
            </button>
          }
        </form>
        @if (hint) {
          <p class="voucher-add-hint gn-text-sm ">{{ hint }}</p>
        }
        @if (presets?.length && company.voucher_config?.use_presets) {
          <div class="gn-w-full gn-mt-4 voucher-presets">
            <p class="gn-text-center gn-font-bold gn-mb-6">or choose from one of our most popular packages...</p>
            <div class="gn-w-full presets-grid">
              @for (item of presets; track item) {
                <div class="gn-relative">
                  @if (item.image_url) {
                    <img [src]="item.image_url" class="gn-w-full gn-h-24 gn-object-cover gn-overflow-hidden" style="border: 3px solid #fff" />
                  }
                  <p class="gn-text-lg gn-font-bold gn-mt-4">{{ item.name }}</p>
                  <div class="gn-mt-4 gn-w-full gn-flex gn-items-center gn-justify-between">
                    <div class="gn-flex gn-items-end">
                      <p class="gn-text-normal font-weight-medium">{{ item.price | currency:company.currency }}</p>
                      @if (+item.old_price) {
                        <p class="gn-text-sm gn-ml-1 gn-line-through">{{ item.old_price | currency:company.currency }}</p>
                      }
                    </div>
                    <button type="button" (click)="selectPreset(item)" class="gn-text-white gn-bg-main gn-text-sm gn-px-2 gn-py-1 gn-rounded">
                      {{ 'buttons.buy_now' | translate }}
                    </button>
                  </div>
                  @if (item.desc) {
                    <p class="gn-text-xs gn-mt-4" [innerHtml]="item.safe_desc"></p>
                  }
                  @if (+item.old_price) {
                    <div class="gn-absolute gn-sale gn-top-0 gn-left-0">
                      <span class="gn-font-bold gn-absolute">SALE</span>
                    </div>
                  }
                </div>
              }
            </div>
          </div>
        }
      </div>
    }
    @if (company && paymentsSetup && cart === '1') {
      <div class="voucher-cart">
        <button (click)="showBasket()">
          <fa-icon [icon]="faShoppingBasket"></fa-icon> ({{ viewBasket.length }})
        </button>
      </div>
    }
    @if (company && !paymentsSetup) {
      <p>You do not have any payment methods connected to your account</p>
    }
  }
  @if (loading) {
    <div class="gn-flex">
      <div [ngClass]="cart === '1' ? 'gn-w-4 gn-h-4' : 'gn-w-16 gn-h-16'" class="gn-border-white gn-border-t-2 gn-rounded-full gn-spin"></div>
    </div>
  }
</div>