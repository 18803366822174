<div class="gn-p-5">
  @for (group of form.controls; track group; let isLast = $last; let i = $index) {
    <div class="w-full" [ngClass]="!isLast ? 'gn-mb-5 gn-pb-5 gn-border-b' : ''">
      <app-voucher
        [form]="group"
        [vouchers]="form"
        [company]="company"
        [currencySymbol]="currency"
        [currency]="company?.currency"
        [index]="i"
        [brandId]="brandId"
        (deleted)="deleted.emit(i)"
        #voucherEl
      ></app-voucher>
    </div>
  }
  @if (!form || !form.length) {
    <p class="gn-text-gray-400 gn-text-center">{{ 'basket.empty' | translate }}</p>
  }
</div>
<form class="gn-w-full gn-p-5 gn-bg-gray-200 gn-text-gray-700 sm:gn-grid md:gn-flex" (ngSubmit)="addVoucher()">
  <div class="gn-flex">
    <p class="gn-text-xs gn-mr-2 gn-self-center">{{ 'basket.add' | translate }}</p>
    <div class="gn-flex gn-mr-2 gn-border gn-border-gray-500 gn-rounded">
      <button type="button" (click)="editVoucherValue(-company.voucher_config.step)" class="gn-px-3 gn-text-lg gn-text-gray-700 gn-font-medium gn-bg-gray-200 hover:gn-bg-gray-400 gn-flex gn-items-center gn-justify-center">
        <span>-</span>
      </button>
      <input [formControl]="amount" [prefix]="currency" mask="0*" class="input gn-bg-white gn-rounded-none gn-w-24 sm:gn-w-20 gn-border-0" type="text" placeholder="Amount" />
      <button type="button" (click)="editVoucherValue(company.voucher_config.step)" class="gn-px-3 gn-text-lg gn-text-gray-700 gn-font-medium gn-bg-gray-200 hover:gn-bg-gray-400 gn-flex gn-items-center gn-justify-center">
        <span>+</span>
      </button>
    </div>
    <button type="submit" class="gn-text-white gn-bg-main gn-px-4 gn-py-2 gn-rounded">
      <fa-icon [icon]="faGiftCard"></fa-icon> {{ 'buttons.add' | translate }}
    </button>
  </div>
  <!--div *ngIf="!showCurrency"  class="gn-flex gn-bg-gray-200 gn-justify-center gn-place-items-end">
  <button (click)="showCurrency=true" class="gn-rounded gn-text-xs gn-mr-2 gn-self-center gn-self-center gn-ml-4 gn-border-2 gn-border-gray-500 gn-p-2 gn-bg-white">Wrong currency ?</button>
</div-->
@if (company?.company_id == '2') {
  <div class="gn-flex gn-bg-gray-200 gn-justify-center gn-place-items-end">
    <p class=" gn-text-xs gn-mr-2 gn-self-center gn-self-center gn-pl-5">Change currency</p>
    <ng-select [(ngModel)]="newCurrency" [ngModelOptions]="{standalone: true}" (change)="changeCurrency($event)" class="gn-w-24">
      @for (curr of currencies ; track curr) {
        <ng-option [value]="curr">{{curr}}
        </ng-option>
      }
    </ng-select>
  </div>
}
</form>

<div class="gn-p-5">
  <div class="gn-flex gn-items-start gn-justify-end gn-text-gray-700">
    <div class="gn-mr-5 gn-text-right">
      <p>{{ 'totals.voucher_total' | translate }}</p>
      <p class="gn-text-sm">{{ 'totals.wrapping' | translate  }}</p>
      <p class="gn-text-sm">{{ 'totals.delivery' | translate }}</p>
      <p class="gn-font-medium gn-text-lg">{{ 'totals.total' | translate }}</p>
    </div>
    <div class="gn-mr-2">
      <p>{{ totalVouchers | currency:company?.currency:'symbol-narrow' }}</p>
      <p class="gn-text-sm">{{ totalWrapping | currency:company?.currency:'symbol-narrow' }}</p>
      <p class="gn-text-sm">{{ totalDelivery | currency:company?.currency:'symbol-narrow' }}</p>
      <p class="gn-font-medium gn-text-lg">{{ total | currency:company?.currency:'symbol-narrow' }}</p>
    </div>
  </div>
  @if (confirmMissingPersonalization) {
    <div >
      <div class=" gn-fixed gn-top-0 gn-left-0 gn-w-full gn-h-full" (click)="confirmMissingPersonalization = false;" style="z-index:1000">
        <div class="confirm_overlay gn-text-black gn-text-lg gn-opacity-100 md:gn-mt-36 md:gn-mx-36 gn-mt-36 gn-mx-8 gn-p-5 gn-border-2 gn-border-gray-500 gn-rounded ">
          <div>
            <h3 class="gn-text-center">Some vouchers do not have a personalised message or a recipient name. Continue anyway ?</h3>
          </div>
          <div>
            <p class="gn-flex gn-items-center  gn-justify-center">
              <a class="gn-mx-4" (click)="confirmMissingPersonalization = false; ">
                <button class="gn-text-white gn-bg-gray-400 gn-px-5 gn-py-2 gn-rounded gn-mt-4 gn-text-xl">No</button>
              </a>
              <a class="gn-mx-4" (click)="confirmMissingPersonalization = false; ignoreMissingPersonalization = true; validate()">
                <button  class="gn-text-white gn-bg-green-500 gn-px-5 gn-py-2 gn-rounded gn-mt-4 gn-text-xl" >Yes</button>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  }
  @if (acceptPayments && form.length > 0) {
    <div class="gn-w-full gn-text-right">
      <button type="button" (click)="validate()" class="gn-text-white gn-bg-main gn-px-5 gn-py-2 gn-rounded gn-mt-4 gn-text-xl">
        {{ 'buttons.pay_now' | translate }}
      </button>
    </div>
  }
</div>
<div class="gn-p-5 gn-bg-gray-200 gn-flex gn-items-center gn-justify-between">
  <div class="gn-flex gn-items-center">
    <img class="gn-object-cover gn-h-10 gn-w-10 gn-mr-2" src="https://images.rezbot.com/widgets/assets/visa.svg" />
    <img class="gn-object-cover gn-h-10 gn-w-10 gn-mr-2" src="https://images.rezbot.com/widgets/assets/master-card.svg" />
    <img class="gn-object-cover gn-h-10 gn-w-10 gn-mr-2" src="https://images.rezbot.com/widgets/assets/american-express.svg" />
    <img class="gn-object-cover gn-h-10 gn-w-10" src="https://images.rezbot.com/widgets/assets/paypal.svg" />
  </div>
  <img class="gn-h-10" src="https://images.rezbot.com/widgets/assets/stripe.png" style="filter: invert(0.8);" />
</div>
